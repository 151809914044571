.upload__progress {
    width: 90%;
    margin: 10px;
}

.imageupload__button {
    margin: 10px;
}

.TextBox {
    display: flex;
    align-items: center;
    padding: 10px;
}

.title {
    padding-right: 5px;
}

.upload__titleTextBox {
    height: 40px;
    width: 200px;
    font-size: large;
}