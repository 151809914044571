.Login__BG_Container {
    display: flex;
    flex-direction: column;
    background: #591934;
    height: 100vh;
    width: 100%;
    align-items: center;
}

.Login__FG_Container {
    display: flex;
    flex-direction: column;
    background-color: #E7CE95;
    height: 50vh;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 165px;
    border-radius: 15px;
    border: 3px solid white;
    box-shadow: 0 5px 15px;
}

.TopCurl {
    position: absolute;
    height: 275px;
}

.BotCurl {
    height: 160px;
}

.LoginForm {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
}

.AdminInp {
    height: 35px;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid black;
    background-color: #c4c4c4;
}

.PasswordInp {
    height: 35px;
    margin-bottom: 30px;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid black;
    background-color: #c4c4c4;
}

.LoginButton {
    height: 40px;
    margin-left: 100px;
    margin-right: 100px;
    background-color: #591934;
    color: white;
    font-size: large;
    border-radius: 5px;
    border: 0px solid black;
    box-shadow: 0px 5px 5px grey;
    cursor: pointer;
}

.LoginButton:hover {
    border: 1px solid white;
}

.LoginButton:active {
    border: 1px solid black;
}