.GalleryCard {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 50px;
    margin-left: 50px;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.1);
}

.GalleryCard__thumbnail {
    width: 400px;
    height: 225px;
    box-shadow: gray 0px 5px 10px;
}

.GalleryCard__title {
    padding: 5px;
}

.Admin__Buttons {
    display: flex;
    float: right;
}

.DeleteButton {
    height: 25px;
    width: 50px;
    margin: 10px;
}

.DeleteButton:hover {
    background-color: red;
    color: white;
    cursor: pointer;
}