a {
    text-decoration: none;
    color: white;
}

.AllBookingsText {
    padding-left: 80px;
    transition-duration: 500ms;
}

.AddBookingsText {
    padding-left: 80px;
    transition-duration: 500ms;
}

.EditBookingsText {
    padding-left: 80px;
    transition-duration: 500ms;
}

.AllBookingsText:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 90px;
}

.AddBookingsText:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 90px;
}

.EditBookingsText:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 90px;
}