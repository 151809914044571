* {
  margin: 0;
  padding: 0;
}

.app_page {
  display: flex;
}

.HeaderToBody {
  display: flex;
  flex-direction: column;
  width: 80%; 
  /* Change Width if the next sync is deformed again */
  flex: 0.8;
}