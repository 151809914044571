.header {
  display: flex;
  height: 79px;
  background-color: white;
  z-index: 99;
}

.headerWrapper {
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 5px gray;
}

.topAvatar {
  margin-left: 800px;
  padding: 0;
  font-size: 50px !important;
  color: #591934;
  border: 2px solid black;
  border-radius: 30px;
  cursor: pointer;
}

.LogoutIcon {
  margin-left: 800px;
  padding: 0;
  font-size: 30px !important;
  color: #591934;
  cursor: pointer;
}

.LogoutIcon:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.topRight {
  display: flex;
  align-items: center;
}

.topLeft {
  display: none;
}

.headerIconContainer {
  position: relative;
  cursor: pointer;
  top: 5px;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.AdminTitle {
  font-weight: 400;
}

.AdminName {
  font-weight: 500;
}