.AddRnC {
    padding-left: 80px;
    cursor: pointer;
    transition-duration: 500ms;
}

.AddRnC:hover {
    background-color: rgba(0, 0, 0, 0.2);
    padding-left: 90px;
}

.EditRnC {
    padding-left: 80px;
    cursor: pointer;
    transition-duration: 500ms;
}

.EditRnC:hover {
    background-color: rgba(0, 0, 0, 0.2);
    padding-left: 90px;
}